:root {
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Segoe UI Emoji", "Apple Color Emoji", "Noto Color Emoji", sans-serif;
  --accent-h: 215.75deg;
  --accent-s: 98.36%;
  --accent-l: 52.16%;
  --accent: hsl(var(--accent-h), var(--accent-s), var(--accent-l));
  --body-max-width: 1200px;
  --background-body: #fff;
  --background-alt: #f7f7f7;
  --background: #efefef;
  --background-input: #fff;
  --selection: #9e9e9e;
  --text-main: #363636;
  --text-bright: #000;
  --text-muted: #70777f;
  --links: var(--accent);
  --focus: var(--accent);
  --border: #b9b8b8;
  --border-radius: 6px;
  --code: #000;
  --animation-duration: .1s;
  --button-base: var(--accent);
  --button-hover: hsl(var(--accent-h), var(--accent-s), calc(var(--accent-l)  - 10%));
  --form-placeholder: #949494;
  --form-text: #1d1d1d;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23161f27'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E");
}

@media (prefers-color-scheme: dark) {
  :root {
    --accent-h: 206;
    --accent-s: 100%;
    --accent-l: 73%;
    --accent: hsl(var(--accent-h), var(--accent-s), var(--accent-l));
    --background-body: #212529;
    --background-alt: #343a40;
    --background: var(--background-alt);
    --background-input: var(--background);
    --selection: #1c76c5;
    --text-main: #dbdbdb;
    --text-bright: #fff;
    --text-muted: #a9b1ba;
    --links: var(--accent);
    --focus: var(--accent);
    --border: #495057;
    --code: #ffbe85;
    --animation-duration: .1s;
    --button-base: var(--accent);
    --button-hover: hsl(var(--accent-h), var(--accent-s), calc(var(--accent-l)  + 5%));
    --form-placeholder: #a9a9a9;
    --form-text: #fff;
    --variable: #d941e2;
    --highlight: #efdb43;
    --select-arrow: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23ffffff'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E");
  }
}

body {
  font-family: var(--font-family);
  max-width: var(--body-max-width);
  word-wrap: break-word;
  color: var(--text-main);
  background: var(--background-body);
  text-rendering: optimizelegibility;
  margin: 20px auto;
  padding: 0 10px;
  line-height: 1.4;
}

button, input, textarea {
  transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
}

h1 {
  margin-top: 0;
  font-size: 2.2rem;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-main);
  margin-top: 24px;
  margin-bottom: 12px;
}

strong {
  color: var(--text-bright);
}

h1, h2, h3, h4, h5, h6, b, strong, th {
  font-weight: 600;
}

q:before, q:after {
  content: none;
}

blockquote, q {
  border-left: 4px solid var(--focus);
  margin: 24px 0;
  padding: 8px 16px;
  font-style: italic;
}

blockquote > footer {
  border: 0;
  font-style: normal;
}

blockquote cite, address {
  font-style: normal;
}

a[href^="mailto:"]:before {
  content: "📧 ";
}

a[href^="tel:"]:before {
  content: "📞 ";
}

a[href^="sms:"]:before {
  content: "💬 ";
}

mark {
  background-color: #ff0;
  background-color: var(--highlight);
  color: #000;
  border-radius: 2px;
  padding: 0 2px;
}

a > code, a > strong {
  color: inherit;
}

button, select {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

input[type="reset"] {
  cursor: pointer;
}

input[type="button"] {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
}

input[type="range"] {
  cursor: pointer;
}

input[type="radio"] {
  cursor: pointer;
}

input, select {
  display: block;
}

[type="checkbox"] {
  display: initial;
}

[type="radio"] {
  display: initial;
}

input {
  color: var(--form-text);
  background-color: var(--background-input);
  font-family: inherit;
  font-size: inherit;
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

button {
  color: var(--form-text);
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

textarea, select {
  color: var(--form-text);
  background-color: var(--background-input);
  font-family: inherit;
  font-size: inherit;
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

button {
  background-color: var(--button-base);
  color: var(--background-body);
  padding-left: 30px;
  padding-right: 30px;
  transition: background-color .15s ease-in-out;
}

input[type="submit"] {
  background-color: var(--button-base);
  color: var(--background-body);
  padding-left: 30px;
  padding-right: 30px;
  transition: background-color .15s ease-in-out;
}

input[type="reset"] {
  background-color: var(--button-base);
  color: var(--background-body);
  padding-left: 30px;
  padding-right: 30px;
  transition: background-color .15s ease-in-out;
}

input[type="button"] {
  background-color: var(--button-base);
  color: var(--background-body);
  padding-left: 30px;
  padding-right: 30px;
  transition: background-color .15s ease-in-out;
}

button:hover {
  background: var(--button-hover);
}

input[type="submit"]:hover {
  background: var(--button-hover);
}

input[type="reset"]:hover {
  background: var(--button-hover);
}

input[type="button"]:hover {
  background: var(--button-hover);
}

input[type="color"] {
  cursor: pointer;
  min-height: 2rem;
  padding: 8px;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
}

input[type="radio"] {
  border-radius: 100%;
}

input {
  vertical-align: top;
}

textarea, select {
  border: 1px solid var(--border);
}

input[type="text"] {
  border: 1px solid var(--border);
}

input[type="url"] {
  border: 1px solid var(--border);
}

input[type="email"] {
  border: 1px solid var(--border);
}

input[type="number"] {
  border: 1px solid var(--border);
}

input[type="password"] {
  border: 1px solid var(--border);
}

input[type="search"] {
  border: 1px solid var(--border);
}

input[type="date"] {
  border: 1px solid var(--border);
}

input[type="datetime-local"] {
  border: 1px solid var(--border);
}

input[type="time"] {
  border: 1px solid var(--border);
}

input[type="week"] {
  border: 1px solid var(--border);
}

input[type="tel"] {
  border: 1px solid var(--border);
}

input[type="file"] {
  border: 1px solid var(--border);
}

label {
  vertical-align: middle;
  margin-bottom: 4px;
  display: inline-block;
}

select, button, textarea {
  -webkit-appearance: none;
  appearance: none;
}

input:not([type="checkbox"]):not([type="radio"]) {
  -webkit-appearance: none;
  appearance: none;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  box-sizing: border-box;
  resize: vertical;
  margin-right: 0;
  display: block;
}

textarea:not([cols]) {
  width: 100%;
}

textarea:not([rows]) {
  height: 140px;
  min-height: 40px;
}

select {
  background: var(--background-input) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
  padding-right: 35px;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  background-image: none;
  padding-right: 10px;
  overflow-y: auto;
}

input:focus-visible {
  box-shadow: 0 0 0 2px var(--focus);
}

select:focus-visible {
  box-shadow: 0 0 0 2px var(--focus);
}

button:focus-visible {
  box-shadow: 0 0 0 2px var(--focus);
}

textarea:focus-visible {
  box-shadow: 0 0 0 2px var(--focus);
}

input:disabled, select:disabled, button:disabled, textarea:disabled {
  cursor: not-allowed;
  opacity: .5;
}

::-moz-placeholder {
  color: var(--form-placeholder);
}

:-ms-input-placeholder {
  color: var(--form-placeholder);
}

::-moz-placeholder {
  color: var(--form-placeholder);
}

::placeholder {
  color: var(--form-placeholder);
}

fieldset {
  border: 1px var(--focus) solid;
  border-radius: var(--border-radius);
  margin: 0 0 12px;
  padding: 10px;
}

legend {
  font-size: .9rem;
  font-weight: 600;
}

input[type="range"] {
  background: none;
  margin: 10px 0;
  padding: 10px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: var(--background);
  border-radius: calc(var(--border-radius) / 2);
  width: 100%;
  height: 9.5px;
  transition: all .2s;
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--accent);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -6px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--background);
}

input[type="range"]::-moz-range-track {
  background: var(--background);
  border-radius: calc(var(--border-radius) / 2);
  width: 100%;
  height: 9.5px;
  transition: all .2s;
}

input[type="range"]::-moz-range-thumb {
  background: var(--accent);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

a {
  color: var(--links);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

code, samp, time {
  background: var(--background);
  color: var(--code);
  border-radius: var(--border-radius);
  padding: 2.5px 5px;
  font-size: .9rem;
}

pre > code {
  padding: 10px;
  display: block;
  overflow-x: auto;
}

pre > code:empty {
  display: none;
}

var {
  color: var(--variable);
  font-family: monospace;
  font-style: normal;
}

kbd {
  background: var(--background);
  border: 1px solid var(--border);
  color: var(--text-main);
  border-radius: 2px;
  padding: 2px 4px;
}

img, video {
  max-width: 100%;
  height: auto;
}

hr {
  border: none;
  border-top: 1px solid var(--border);
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 10px;
}

table caption {
  text-align: left;
}

td, th {
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
  padding: 6px;
}

thead {
  border-bottom: 1px solid var(--border);
}

tfoot {
  border-top: 1px solid var(--border);
}

tbody tr:nth-child(2n) {
  background-color: var(--background);
}

tbody tr:nth-child(2n) button {
  background-color: var(--background-alt);
}

tbody tr:nth-child(2n) button:hover {
  background-color: var(--background-body);
}

details {
  background-color: var(--background-alt);
  border-radius: var(--border-radius);
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0;
  padding: 10px 10px 0;
  display: flex;
  overflow: hidden;
}

details[open] {
  padding: 10px;
}

details > :last-child {
  margin-bottom: 0;
}

details summary {
  transition: margin var(--animation-duration) ease-out;
}

details[open] summary {
  margin-bottom: 10px;
}

summary {
  background-color: var(--background);
  cursor: pointer;
  outline: none;
  margin: -10px -10px 0;
  padding: 10px;
  display: list-item;
}

details > :not(summary) {
  margin-top: 0;
}

summary::-webkit-details-marker {
  color: var(--text-main);
}

dialog {
  background-color: var(--background-alt);
  color: var(--text-main);
  border-radius: var(--border-radius);
  border: none;
  border-color: var(--border);
  padding: 10px 30px;
}

dialog > header:first-child {
  background-color: var(--background);
  text-align: center;
  border-radius: 6px 6px 0 0;
  margin: -10px -30px 10px;
  padding: 10px;
}

dialog::backdrop {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, .61);
}

footer {
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
  color: var(--text-muted);
  padding-top: 10px;
}

body > footer {
  margin-top: 40px;
}

@media print {
  body, pre, code, summary, details, button, input, textarea {
    background-color: #fff;
  }

  button, input, textarea {
    border: 1px solid #000;
  }

  body, h1, h2, h3, h4, h5, h6, pre, code, button, input, textarea, footer, summary, strong {
    color: #000;
  }

  summary::marker {
    color: #000;
  }

  summary::-webkit-details-marker {
    color: #000;
  }

  tbody tr:nth-child(2n) {
    background-color: #f2f2f2;
  }

  a {
    color: #00f;
    text-decoration: underline;
  }
}

/*# sourceMappingURL=index.a6923299.css.map */
